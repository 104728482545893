/* eslint-disable @nx/enforce-module-boundaries */
import React, { useContext, useEffect, useRef } from 'react';
import { Card, Text, HStack, Image, VStack, Button } from '@mybridge/ui';
import { CALL_TO_ACTION } from 'v4/lib/data';
import _ from 'lodash';
import { PostBoxContext, usePostBoxContext } from 'v4/components/post/context';
import { useDispatch } from 'react-redux';
import { updateCampaignPerformanceReport } from 'v4/store/actions/campaign.action';

const SpotlightBox = ({ campaignDetails, isDashboard, ...props }) => {
 const dispatch = useDispatch();
 const targetRef = useRef(null);
  const handleAdClicksRouting = async (type, postId, url) => {
    // console.log(type, postId, url);
    const getType = (type) => {
      switch(type) {
        case 'click':
          return 2;
        case 'reach':
          return 5;
        case 'impression':
          return 1;
        case 'video':
          return 4;
        case 'conversion':
          return 3;
        default:
          return 0;
      }
    }
    
    const payload = {
        id:postId, body:{analytic_type:getType(type)}
    }
    // const currentData = [100:{'click', 'reach', 'impression'}, 101:{'click', 'reach', 'impression'}];
    // if(currentPerformance?.)
    dispatch(updateCampaignPerformanceReport(payload))
    if(url){
      window.open(url, '_blank', 'noopener,noreferrer')
    }

  }

  useEffect(() => {
    if (isDashboard) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (isDashboard) {
            // setIsVisible(entry.isIntersecting);
            handleAdClicksRouting('impression', campaignDetails?.id,);
            handleAdClicksRouting('reach', campaignDetails?.id,);
          }
        },
        {
          root: null, // viewport
          rootMargin: '0px', // no margin
          threshold: 0.2, // 50% of target visible
        }
      );

      if (targetRef.current) {
        observer.observe(targetRef.current);
      }

      // Clean up the observer
      return () => {
        if (targetRef.current) {
          observer.unobserve(targetRef.current);
        }
      };
    }
  }, []);

  return (
      <Card
        p={isDashboard ? 0 : 3}
        w="255px"
        variant={isDashboard ? '' : 'thinBorderedCard'}
        bg={isDashboard ? 'transparent' : 'white'}
        mb={3}
        pos="relative"
        boxShadow="none"
        ref={targetRef}
      >
        {/* <Text fontSize="18px" color="brandPrimary.500" mb={3}>
        Sponsored
      </Text> */}
        <HStack gap={3} alignItems="flex-start">
          {campaignDetails?.ad_format_detail?.image ||
          campaignDetails?.image ? (
            <Image
              src={
                campaignDetails?.ad_format_detail?.image ??
                campaignDetails?.image
              }
              alt={
                campaignDetails?.ad_format_detail?.headline ??
                campaignDetails?.headline
              }
              w="95px"
              h="95px"
              style={{marginTop:"6px"}}
              borderRadius="8%"
              onClick={() =>
                isDashboard
                  ? handleAdClicksRouting(
                    'conversion',
                    campaignDetails?.id,
                    campaignDetails?.ad_format_detail?.destination_url
                  )
                  : undefined
              }
              cursor={isDashboard ? 'pointer' : 'default'}
            />
          ) : (
            <></>
          )}

          <VStack gap={0} alignItems="flex-start">
            <Text fontSize="17px" color="brandPrimary.500" onClick={() =>
                isDashboard
                  ? handleAdClicksRouting(
                    'conversion',
                    campaignDetails?.id,
                    campaignDetails?.ad_format_detail?.destination_url
                  )
                  : undefined
              }
              cursor={isDashboard ? 'pointer' : 'default'}>
              {campaignDetails?.ad_format_detail?.headline ??
                campaignDetails?.headline}
            </Text>
            <Text fontSize="10.3px" color="#5B5B5B">
              {campaignDetails?.ad_format_detail?.description ??
                campaignDetails?.description}
            </Text>
          {(campaignDetails?.ad_format_detail?.headline ||
                campaignDetails?.headline)&&(  <Button
              variant="secondary"
              fontSize="10px"
              h="28px"
              minW="78px"
              p={1}
              isDisabled={!isDashboard ? true : false}
              onClick={() =>
                handleAdClicksRouting(
                  'conversion',
                  campaignDetails?.id,
                  campaignDetails?.ad_format_detail?.destination_url
                )
              }
            >
              {campaignDetails?.ad_format_detail?.call_to_action ||
              campaignDetails?.call_to_action !== 'Select Option'
                ? _.filter(
                    CALL_TO_ACTION,
                    (item) =>
                      item?.value ===
                        campaignDetails?.ad_format_detail?.call_to_action ||
                      campaignDetails?.call_to_action
                  )?.[0]?.label
                : 'Call to action'}
            </Button>)}
          </VStack>
        </HStack>
      </Card>
  );
};

export default SpotlightBox;
